import axios from "axios";

const state = {
    MENU_HEADER: null,
    MENU_FOOTER: null,
}

const getters = {
    GET_MENU_HEADER(state) {
        return state.MENU_HEADER
    },
    GET_MENU_FOOTER(state) {
        return state.MENU_FOOTER
    },
}

const mutations = {
    SET_MENU_HEADER(state, val) {
        state.MENU_HEADER = val
    },
    SET_MENU_FOOTER(state, val) {
        state.MENU_FOOTER = val
    },
}

const actions = {
    async FETCH_MENU({commit}) {
        // store.commit('MainModule/SET_PRELOADER_STATE', true)
        return await axios.get(`${process.env.VUE_APP_API_URL}menus`, {
            params: {
                with_items: 1
            },
        })
            .then(({data}) => {
                if (data) {
                    commit('SET_MENU_HEADER', data.filter(item => item.position === 'header')[0].items)
                    commit('SET_MENU_FOOTER', data.filter(item => item.position === 'footer')[0].items)
                }
            })
            .catch((err) => {
                throw new Error(err)
            })
            // .finally(() => {
            //     // store.commit('MainModule/SET_PRELOADER_STATE', false)
            // })
    },
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}