<template>
  <span class="hamburger" :class="{ 'active' : GET_MENU_STATE }" @click="toggleMenu">
    <span class="bar bar1"></span>
    <span class="bar bar2"></span>
    <span class="bar bar3"></span>
    <span class="bar bar4"></span>
  </span>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";

export default {
  name: "BurgerApp",
  props: {
    removeActiveClass: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    ...mapGetters('MainModule', ['GET_MENU_STATE']),
  },
  methods: {
    ...mapMutations('MainModule', ['SET_MENU_STATE']),
    toggleMenu() {
      this.SET_MENU_STATE(!this.GET_MENU_STATE)
    },
  },
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/_bootstrap_includes.scss';

.hamburger {
  display: inline-block;
  width: 67px;
  height: 20px;
  position: relative;
  cursor: pointer;
  @include media-breakpoint-down(xxl) {
    width: 54px;
  }
  @include media-breakpoint-down(lg) {
    width: 40px;
  }
}

.hamburger .bar {
  padding: 0;
  border-top: 1px solid #0D0E0E;
  border-bottom: 1px solid #0D0E0E;
  display: block;
  -webkit-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  position: absolute;
  will-change: transform;
}

.bar1 {
  width: 100%;
  top: 1px;
  will-change: border-color, width;
}

.bar2 {
  width: 0;
  right: 0;
  top: 9px;
  border-color: transparent;
  will-change: border-color, width;
}

.bar3 {
  right: 0;
  width: 35%;
  top: 9px;
  will-change: width;
}

.bar4 {
  width: 65%;
  right: 0;
  bottom: 2px;
  will-change: border-color;
  @include media-breakpoint-down(md) {
    bottom: 3px;
  }
}

.hamburger {
  &.active {
    .bar1 {
      -webkit-transform: translateX(40px);
      -ms-transform: translateX(40px);
      transform: translateX(40px);
      border-color: transparent;
      width: 0;
    }

    .bar2 {
      width: 42%;
      right: 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
      border-color: #0D0E0E;
      @include media-breakpoint-down(lg) {
        width: 60%;
      }
    }

    .bar3 {
      width: 42%;
      -webkit-transform: rotate(-45deg);
      -ms-transform: rotate(-45deg);
      transform: rotate(-45deg);
      @include media-breakpoint-down(lg) {
        width: 60%;
      }
    }

    .bar4 {
      -webkit-transform: translateX(-40px);
      -ms-transform: translateX(-40px);
      transform: translateX(-40px);
      border-color: transparent;
    }
  }
}
</style>