import axios from "axios";
import store from "@/store";

const state = {
    FORM_MODAL_TEXT_SUCCESS: 'Спасибо за&nbsp;заявку! Ваши данные отправлены, мы&nbsp;свяжемся с&nbsp;вами в&nbsp;ближайшее время!',
    FORM_MODAL_TEXT_ERROR: 'При отправке данных произошла ошибка.',
    FORM_MODAL_TEXT: '',
}

const getters = {
    GET_FORM_MODAL_TEXT_SUCCESS(state) {
        return state.FORM_MODAL_TEXT_SUCCESS
    },
    GET_FORM_MODAL_TEXT_ERROR(state) {
        return state.FORM_MODAL_TEXT_ERROR
    },
    GET_FORM_MODAL_TEXT(state) {
        return state.FORM_MODAL_TEXT
    },
}

const mutations = {
    SET_FORM_MODAL_TEXT(state, val) {
        state.FORM_MODAL_TEXT = val
    },
}

const actions = {
    async SEND_FORM({commit}, payload) {
        store.commit('MainModule/SET_FORM_PRELOADER_STATE', true)
        return await axios.post(`${process.env.VUE_APP_API_URL}leads`, payload)
            .then((res) => {
                if (res.status === 201) {
                    store.commit('FormModule/SET_FORM_MODAL_TEXT', store.getters["FormModule/GET_FORM_MODAL_TEXT_SUCCESS"])
                } else {
                    store.commit('FormModule/SET_FORM_MODAL_TEXT', store.getters["FormModule/GET_FORM_MODAL_TEXT_ERROR"])
                }
            })
            .catch((err) => {
                store.commit('FormModule/SET_FORM_MODAL_TEXT', store.getters["FormModule/GET_FORM_MODAL_TEXT_ERROR"])
                throw new Error(err)
            })
            .finally(() => {
                store.commit('MainModule/SET_FORM_PRELOADER_STATE', false)
            })
    },
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}