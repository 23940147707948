<template>
  <footer class="footer">
    <div class="container-fluid">
      <section
          class="footer__top d-flex justify-content-between align-items-start align-items-sm-center flex-column flex-sm-row">
        <div class="">
          <h3 class="footer__title">расскажите <br/> о&nbsp;своём проекте</h3>
        </div>
        <div class="align-self-start align-self-sm-end align-self-md-center flex-shrink-0">
          <button class="btn btn-outline-primary" @click="SET_BRIEF_STATE(true)">заполнить бриф</button>
        </div>
      </section>
      <span class="divider"></span>
      <section class="footer__middle">
        <div class="footer__logo position-relative d-inline-flex align-items-center">
          <img src="@/assets/logo.svg" class="footer__logo-img me-2 me-md-3" alt="ВЕБ-Киборг"/>
          <router-link to="/" class="footer__logo-link fw-semibold stretched-link">ВЕБ-Киборг
          </router-link>
        </div>
        <div class="footer__main d-flex flex-wrap justify-content-between flex-column flex-lg-row">
          <div class="footer__contacts">
            <p class="contact-us mb-3 mb-lg-4">Свяжитесь с&nbsp;нами удобным способом</p>
            <address>
              <div class="mb-2 mb-md-3">
                <a :href="GET_EMAIL_LINK"
                   class="footer__addr hover-link">
                  {{ GET_EMAIL }}
                </a>
              </div>
              <div><a :href="GET_PHONE_LINK" class="footer__addr hover-link">{{ GET_FORMATTED_PHONE }}</a>
              </div>
            </address>
          </div>
          <div class="right-block">
            <nav class="d-flex justify-content-between flex-column flex-md-row">
              <div class="row flex-column">
                <p class="footer__nav-item text-secondary text-uppercase col-12 mb-2 mb-lg-3">Меню</p>
                <div v-if="GET_MENU_FOOTER"
                     class="d-flex flex-wrap flex-row flex-md-column justify-content-start justify-content-md-between col-12"
                     style="row-gap: 8px">
                  <div v-for="item in GET_MENU_FOOTER" :key="item.id" class="nav-item-wrapper pe-4 pe-md-0">
                    <menu-item
                        :item="item"
                        add-class="footer__nav-item mb-2 d-inline">
                    </menu-item>
                  </div>
                  <div v-if="GET_SOCIAL_HH" class="nav-item-wrapper pe-4 pe-md-0">
                    <a :href="GET_SOCIAL_HH" rel="noopener nofollow" target="_blank"
                       class="footer__nav-item mb-2 hover-link d-inline">Вакансии</a>
                  </div>
                  <div v-if="GET_SOCIAL_FLAMP" class="nav-item-wrapper pe-4 pe-md-0">
                    <a :href="GET_SOCIAL_FLAMP" rel="noopener nofollow" target="_blank"
                       class="footer__nav-item mb-2 hover-link d-inline">Отзывы на Flamp</a>
                  </div>
                </div>
              </div>
              <div class="row flex-column order-2 order-md-1">
                <p class="footer__nav-item text-secondary text-uppercase col-12 mb-2 mb-lg-3">Соцсети</p>
                <div
                    class="d-flex flex-wrap flex-row flex-md-column justify-content-start justify-content-md-between col-12"
                    style="row-gap: 8px">
                  <div v-if="GET_SOCIAL_BEHANCE" class="nav-item-wrapper pe-4 pe-md-0">
                    <a :href="GET_SOCIAL_BEHANCE" rel="noopener nofollow" target="_blank"
                       class="footer__nav-item mb-2 hover-link d-inline">Behance</a>
                  </div>
                  <div v-if="GET_SOCIAL_VK" class="nav-item-wrapper pe-4 pe-md-0">
                    <a :href="GET_SOCIAL_VK" rel="noopener nofollow" target="_blank"
                       class="footer__nav-item hover-link d-inline">Vkontakte</a>
                  </div>
                  <div v-if="GET_SOCIAL_INST" class="nav-item-wrapper pe-4 pe-md-0">
                    <a :href="GET_SOCIAL_INST" rel="noopener nofollow" target="_blank"
                       class="footer__nav-item hover-link d-inline">Inst</a>
                  </div>
                  <div v-if="GET_SOCIAL_YOUTUBE" class="nav-item-wrapper pe-4 pe-md-0">
                    <a :href="GET_SOCIAL_YOUTUBE" rel="noopener nofollow" target="_blank"
                       class="footer__nav-item hover-link d-inline">YouTube </a>
                  </div>
                </div>
              </div>
              <div class="row flex-column order-1 order-md-2">
                <p class="footer__nav-item text-secondary text-uppercase col-12 mb-2 mb-lg-3">Наш офис</p>
                <div class="col-12">
                  <address>
                    <p class="footer__nav-item mb-2" v-html="GET_ADDRESS"></p>
                    <p class="footer__nav-item mb-2" v-html="GET_WORK_TIMES"></p>
                  </address>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </section>
      <span class="divider divider_bottom"></span>
      <section class="footer_bottom row">
        <p class="col-lg-7 col-md-6 mb-0 order-2 order-md-0">&copy;&nbsp;ООО «ВЕБ-Киборг» {{ GET_COPYRIGHT_YEAR_FROM }}
          — {{ yearNow }}</p>
        <div class="col-lg-5 col-md-6 d-flex flex-wrap flex-column flex-md-row justify-content-between">
          <router-link
              :to="{ name: 'static_page', params: { slug: 'policy' } }"
              class="hover-link order-1 mb-2 mb-md-0 d-inline">
            Политика конфиденциальности
          </router-link>
          <router-link
              :to="{ name: 'static_page', params: { slug: 'cookies' } }"
              class="hover-link order-0 order-md-2 mb-2 mb-md-0 d-inline">
            Cookies
          </router-link>
        </div>
      </section>
    </div>
  </footer>
</template>

<script>
import {mapGetters, mapMutations} from 'vuex'
import MenuItem from "@/components/UI/MenuApp/MenuItem";

export default {
  name: "FooterApp",
  components: {
    MenuItem,
  },
  computed: {
    ...mapGetters('SettingsModule', ['GET_EMAIL', 'GET_EMAIL_LINK', 'GET_FORMATTED_PHONE', 'GET_PHONE_LINK', 'GET_SOCIAL_INST',
      'GET_SOCIAL_FLAMP', 'GET_SOCIAL_VK', 'GET_SOCIAL_HH', 'GET_SOCIAL_YOUTUBE', "GET_SOCIAL_BEHANCE", 'GET_WORK_TIMES',
      'GET_ADDRESS', 'GET_COPYRIGHT_YEAR_FROM']),
    ...mapGetters('MenuModule', ['GET_MENU_FOOTER']),
    yearNow() {
      return new Date().getFullYear()
    },
  },
  methods: {
    ...mapMutations('MainModule', ['SET_BRIEF_STATE']),
  },
}
</script>

<style lang="scss">
@import '@/assets/styles/_bootstrap_includes.scss';

.footer {
  position: relative;
  z-index: 1;
  background-color: $green-white;
  padding: 115px 0 30px;
  border-radius: 70px 70px 0 0;
  @include media-breakpoint-down(xxl) {
    padding-top: 84px;
  }
  @include media-breakpoint-down(xl) {
    padding-top: 58px;
    border-radius: 50px 50px 0 0;
  }
  @include media-breakpoint-down(lg) {
    border-radius: 40px 40px 0 0;
  }

  &__title {
    margin-bottom: 0;
    @include media-breakpoint-down(sm) {
      margin-bottom: 36px;
    }
  }

  &__top {
    margin-bottom: 118px;
    @include media-breakpoint-down(xxl) {
      margin-bottom: 74px;
    }
    @include media-breakpoint-down(xl) {
      margin-bottom: 60px;
    }
    @include media-breakpoint-down(lg) {
      margin-bottom: 48px;
    }
  }

  &__middle {
    margin-bottom: 78px;
    @include media-breakpoint-down(xxl) {
      margin-bottom: 84px;
    }
    @include media-breakpoint-down(xl) {
      margin-bottom: 72px;
    }
    @include media-breakpoint-down(lg) {
      margin-bottom: 60px;
    }
    @include media-breakpoint-down(md) {
      margin-bottom: 40px;
    }

    .right-block {
      @include media-breakpoint-down(lg) {
        width: 100%;
      }
    }
  }

  &__logo {
    margin-bottom: 40px;
    @include media-breakpoint-down(xl) {
      margin-bottom: 46px;
    }
    @include media-breakpoint-down(md) {
      margin-bottom: 30px;
    }
  }

  &__logo-img {
    width: 60px;
    height: 43px;
    @include media-breakpoint-down(xl) {
      width: 55px;
      height: 29px;
    }
    @include media-breakpoint-down(md) {
      width: 40px;
      height: 21px;
    }
  }

  &__logo-link {
    font-size: $font-size-xl;
    @include media-breakpoint-down(xl) {
      font-size: $font-size-lg;
    }
    @include media-breakpoint-down(md) {
      font-size: $font-size-base;
    }
    @include media-breakpoint-down(md) {
      font-size: $font-size-xs;
    }
  }

  &__main {
    row-gap: 40px;
  }

  .contact-us {
    font-size: $font-size-xs;
    @include media-breakpoint-down(xl) {
      font-size: $font-size-base;
    }
    @include media-breakpoint-down(md) {
      font-size: $font-size-xs;
    }
  }

  &__contacts {
    @include media-breakpoint-down(md) {
      margin-bottom: 0;
    }
  }

  nav {
    @include media-breakpoint-down(md) {
      row-gap: 40px;
    }
    @include media-breakpoint-down(sm) {
      row-gap: 32px;
    }
  }

  &__addr {
    font-size: $font-size-base * 2.571; //36
    @include media-breakpoint-down(xxl) {
      font-size: $font-size-2xl;
    }
    @include media-breakpoint-down(xl) {
      font-size: $font-size-xl;
    }
    @include media-breakpoint-down(lg) {
      font-size: $font-size-lg;
    }
    @include media-breakpoint-down(md) {
      font-size: $font-size-xs;
      font-weight: $font-weight-bold;
    }
  }

  .nav-item-wrapper {
    @include media-breakpoint-down(md) {
      width: 33%;
    }
    @include media-breakpoint-down(sm) {
      width: 50%;
    }
  }

  &__nav-item {
    font-size: $font-size-xs;

    &:not(p) {
      width: 100%;
    }

    @include media-breakpoint-down(xl) {
      font-size: $font-size-base;
    }
    @include media-breakpoint-down(md) {
      font-size: $font-size-xs;
    }
  }

  .divider {
    margin-bottom: 76px;

    &_bottom {
      margin-bottom: 26px;
    }

    @include media-breakpoint-down(xxl) {
      &_bottom {
        margin-bottom: 33px;
      }
    }
    @include media-breakpoint-down(xl) {
      margin-bottom: 62px;
      &_bottom {
        margin-bottom: 27px;
      }
    }
    @include media-breakpoint-down(md) {
      margin-bottom: 40px;
      &_bottom {
        margin-bottom: 34px;
      }
    }
  }
}
</style>