import axios from "axios";
import store from "@/store";
import router from "@/router";

const state = {
    WORKS: null,
    CURRENT_WORK: null,
}

const getters = {
    GET_WORKS(state) {
        return state.WORKS
    },
    GET_CURRENT_WORK(state) {
        return state.CURRENT_WORK
    },
}

const mutations = {
    SET_WORKS(state, val) {
        state.WORKS = val
    },
    SET_CURRENT_WORK(state, val) {
        state.CURRENT_WORK = val
    },
}

const actions = {
    async FETCH_WORKS({commit}, onHomePage = false) {
        const params = {}
        if (onHomePage) {
          params.show_on_home = 1
        }

        return await axios.get(`${process.env.VUE_APP_API_URL}works`, {
            params: params,
        })
            .then(({data}) => {
                commit('SET_WORKS', data)
            })
            .catch((err) => {
                throw new Error(err)
            })
    },
    async FETCH_CURRENT_WORK({commit}, payload) {
        store.commit('MainModule/SET_PRELOADER_STATE', true)
        return await axios.get(`${process.env.VUE_APP_API_URL}works/${payload.slug}`)
            .then(({data}) => {
                store.commit('WorksModule/SET_CURRENT_WORK', data)
                const metaData = {
                    meta_title: data.meta_title,
                    meta_description: data.meta_description,
                    meta_tags: data.meta_tags,
                }
                store.commit('PagesModule/SET_PAGE', metaData)

            })
            .catch((err) => {
                if (err.response && err.response.status === 404) {
                    router.push({
                        name: 'notFound',
                        params: {
                            pathMatch: payload.path,
                        },
                    })
                }
                throw new Error(err)
            })
    },
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}