import axios from "axios";

const state = {
    REVIEWS: null,
}

const getters = {
    GET_REVIEWS(state) {
      return state.REVIEWS
    },
}

const mutations = {
    SET_REVIEWS(state, val) {
        state.REVIEWS = val
    },
}

const actions = {
    async FETCH_REVIEWS({commit}) {
        return await axios.get(`${process.env.VUE_APP_API_URL}reviews`)
            .then(({data}) => {
                if (data) {
                    commit('SET_REVIEWS', data)
                }
            })
            .catch((err) => {
                throw new Error(err)
            })
    },
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}