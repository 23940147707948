<template>
  <router-link
      v-if="!item.path"
      :to="{ name: item.page_slug }"
      class="hover-link"
      :class="addClass"
      v-html="item.name"
      @click="menuClick">
  </router-link>
  <a
      v-else
      :href="item.path"
      target="_blank"
      rel="noopener nofollow"
      class="hover-link lh-1"
      :class="addClass"
      v-html="item.name">
  </a>
  <router-link
      v-if="item.page.template"
      :to="{ name: item.page.template, params: { slug: item.page.slug } }"
      class="hover-link"
      :class="addClass"
      v-html="item.name"
      @click="menuClick">
  </router-link>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";

export default {
  name: "MenuItem",
  props: {
    addClass: {
      type: String,
    },
    item: {
      type: Object,
      default: () => {
      }
    },
  },
  computed: {
    ...mapGetters('MainModule', ['GET_MENU_STATE']),
  },
  methods: {
    ...mapMutations('MainModule', ['SET_MENU_STATE']),
    menuClick() {
      if (this.GET_MENU_STATE) {
        this.SET_MENU_STATE(false)
      }
    },
  },
}
</script>

<style scoped>

</style>