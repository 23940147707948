import { createStore } from 'vuex'

import SettingsModule from "@/store/modules/settings";
import MenuModule from "@/store/modules/menu";
import PagesModule from "@/store/modules/pages";
import MainModule from "@/store/modules/main";
import WorksModule from "@/store/modules/works";
import ServicesModule from "@/store/modules/services";
import FormModule from "@/store/modules/form";
import ReviewsModule from "@/store/modules/reviews";

export default createStore({
  modules: {
    SettingsModule,
    MenuModule,
    PagesModule,
    MainModule,
    WorksModule,
    ServicesModule,
    FormModule,
    ReviewsModule,
  }
})
