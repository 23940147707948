import axios from "axios";
import store from "@/store";
import router from "@/router";

const state = {
    SERVICES: null,
    CURRENT_SERVICE: null,
}

const getters = {
    GET_SERVICES(state) {
        return state.SERVICES
    },
    GET_FILTERED_SERVICES: (state) => (slug) => {
        return state.SERVICES.filter(item => item.slug !== slug)
    },
    GET_CURRENT_SERVICE(state) {
        return state.CURRENT_SERVICE
    },
}

const mutations = {
    SET_SERVICES(state, val) {
        state.SERVICES = val
    },
    SET_CURRENT_SERVICE(state, val) {
        state.CURRENT_SERVICE = val
    },
}

const actions = {
    async FETCH_SERVICES({commit}) {
        return await axios.get(`${process.env.VUE_APP_API_URL}services`)
            .then(({data}) => {
                commit('SET_SERVICES', data)
            })
            .catch((err) => {
                throw new Error(err)
            })
    },
    async FETCH_CURRENT_SERVICE({commit}, payload) {
        store.commit('MainModule/SET_PRELOADER_STATE', true)
        return await axios.get(`${process.env.VUE_APP_API_URL}services/${payload.slug}`)
            .then(({data}) => {
                commit('SET_CURRENT_SERVICE', data)
                const metaData = {
                    meta_title: data.meta_title,
                    meta_description: data.meta_description,
                    meta_tags: data.meta_tags,
                }
                store.commit('PagesModule/SET_PAGE', metaData)
            })
            .catch((err) => {
                if (err.response && err.response.status === 404) {
                    router.push({
                        name: 'notFound',
                        params: {
                            pathMatch: payload.path,
                        },
                    })
                }
                throw new Error(err)
            })
    },
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}