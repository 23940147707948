<template>
  <header class="header py-md-4 py-3 position-fixed w-100">
    <div class="container-fluid d-flex align-items-center justify-content-between">
      <div class="logo position-relative d-flex align-items-center">
        <img src="@/assets/logo.svg" class="logo__img me-2 me-md-3" alt="ВЕБ-Киборг"
             @click="SET_MENU_STATE(false);$router.push('/')"/>
        <router-link to="/" @click="SET_MENU_STATE(false)" class="logo__link fs-3 fw-semibold stretched-link">
          ВЕБ-Киборг
        </router-link>
      </div>
      <div class="d-flex align-items-center">
        <a :href="GET_PHONE_LINK" class="header__phone hover-link me-5 d-none d-md-inline">{{ GET_FORMATTED_PHONE }}</a>
        <a href="#"
           @click.prevent="GET_BRIEF_STATE ? SET_BRIEF_STATE(false) : SET_BRIEF_STATE(true)"
           class="brief-link d-none d-sm-inline hover-link">
          Заполнить бриф
        </a>
        <burger-app></burger-app>
      </div>
    </div>
    <menu-app :show-menu="GET_MENU_STATE" @openBrief="openBrief"></menu-app>
    <brief-app :show-brief="GET_BRIEF_STATE" @closeBrief="closeBrief"></brief-app>
  </header>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";
import {defineAsyncComponent} from "vue";
import BurgerApp from "@/components/UI/BurgerApp/BurgerApp.vue";

export default {
  name: "HeaderApp",
  data() {
    return {}
  },
  components: {
    BurgerApp,
    MenuApp: defineAsyncComponent(() =>
        import('@/components/UI/MenuApp/MenuApp.vue')),
    BriefApp: defineAsyncComponent(() =>
        import('@/components/BriefApp/BriefApp.vue')),
  },
  computed: {
    ...mapGetters('MainModule', ['GET_MENU_STATE', 'GET_BRIEF_STATE']),
    ...mapGetters('SettingsModule', ['GET_FORMATTED_PHONE', 'GET_PHONE_LINK']),
  },
  methods: {
    ...mapMutations('MainModule', ['SET_MENU_STATE', 'SET_BRIEF_STATE']),
    closeBrief() {
      this.SET_BRIEF_STATE(false)
    },
    openBrief() {
      this.SET_BRIEF_STATE(true)
    },
  },
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/_bootstrap_includes.scss';

.header {
  background-color: $white;
  z-index: 1000;

  &__phone {
    font-size: $font-size-base * 1.71;
    @include media-breakpoint-down(xxl) {
      font-size: $font-size-lg;
    }
    @include media-breakpoint-down(xl) {
      font-size: $font-size-xs;
    }
  }
}

.logo {

  &__img {
    width: 80px;
    height: 43px;
    @include media-breakpoint-down(md) {
      width: 46px;
      height: 25px;
    }
    @include media-breakpoint-down(sm) {
      width: 41px;
      height: 23px;
    }
  }

  &__link {
    @include media-breakpoint-down(xl) {
      font-size: $font-size-lg !important;
    }
    @include media-breakpoint-down(lg) {
      font-size: $font-size-xs !important;
    }
    @include media-breakpoint-down(sm) {
      visibility: hidden;
    }
  }
}

.brief-link {
  margin-right: 118px;
  font-size: $font-size-base * 1.71;
  @include media-breakpoint-down(xxl) {
    margin-right: 63px;
    font-size: $font-size-lg;
  }
  @include media-breakpoint-down(xl) {
    margin-right: 60px;
    font-size: $font-size-xs;
  }
  @include media-breakpoint-down(lg) {
    margin-right: 75px;
  }
}
</style>