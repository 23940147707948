import axios from "axios";
import store from "@/store";
import router from "@/router";

const state = {
    PAGE: null,
    INDEX_PAGE_SLUG: 'index',
    ABOUT_PAGE_SLUG: 'about',
    SERVICES_PAGE_SLUG: 'services',
    WORKS_PAGE_SLUG: 'works',
    CONTACTS_PAGE_SLUG: 'contacts',
    PAGE_META_TITLE: null,
    PAGE_META_DESCRIPTION: null,
    PAGE_META_TAG: null,
    PAGE_META_TAGS_ARR: null,
}

const getters = {
    GET_PAGE(state) {
        return state.PAGE
    },
    GET_INDEX_PAGE_SLUG(state) {
        return state.INDEX_PAGE_SLUG
    },
    GET_ABOUT_PAGE_SLUG(state) {
        return state.ABOUT_PAGE_SLUG
    },
    GET_SERVICES_PAGE_SLUG(state) {
        return state.SERVICES_PAGE_SLUG
    },
    GET_WORKS_PAGE_SLUG(state) {
        return state.WORKS_PAGE_SLUG
    },
    GET_CONTACTS_PAGE_SLUG(state) {
        return state.CONTACTS_PAGE_SLUG
    },
    GET_PAGE_META_TAGS_ARR(state) {
        return state.PAGE_META_TAGS_ARR
    },
}

const mutations = {
    SET_PAGE(state, val) {
        if (val) {
            state.PAGE = val
            document.title = val === 'notFound' ? 'Страница не найдена' : val.meta_title

            if (state.PAGE_META_TAGS_ARR) {
                state.PAGE_META_TAGS_ARR.forEach(item => {
                    document.querySelector(`meta[name=${item.name}]`).remove()
                })
                state.PAGE_META_TAGS_ARR = null
            }

            if (val.meta_tags && val.meta_tags.length !== 0) {
                store.commit('PagesModule/SET_PAGE_META_TAGS_ARR', val.meta_tags)
                val.meta_tags.forEach(item => {
                    const metaTag = document.createElement('meta')
                    metaTag.setAttribute('name', item.name)
                    metaTag.setAttribute('content', item.content)
                    document.head.append(metaTag)
                })
            }

            if (!state.PAGE_META_TAG && val.meta_description) {
                store.commit('PagesModule/SET_PAGE_META_TAG', document.createElement('meta'))
                state.PAGE_META_TAG.setAttribute('name', 'description')
                document.head.append(state.PAGE_META_TAG)
            }
            if (!val.meta_description) {
                try {
                    state.PAGE_META_TAG.remove()
                    state.PAGE_META_TAG = null
                } catch (e) {
                }
            } else {
                state.PAGE_META_TAG.setAttribute('content', val.meta_description)
            }
        }
    },
    SET_PAGE_META_TAG(state, val) {
        state.PAGE_META_TAG = val
    },
    SET_PAGE_META_TAGS_ARR(state, val) {
        state.PAGE_META_TAGS_ARR = val
    },
}

const actions = {
    async FETCH_PAGE({commit}, page_slug) {
        store.commit('MainModule/SET_PRELOADER_STATE', true)
        commit('SET_PAGE', null)
        return await axios.get(`${process.env.VUE_APP_API_URL}pages/${page_slug}`)
            .then(({data}) => {
                commit('SET_PAGE', data)
            })
            .catch((err) => {
                if (err.response && err.response.status === 404) {
                    router.push({
                        name: 'notFound',
                        params: {
                            pathMatch: page_slug,
                        },
                    })
                }
                throw new Error(err)
            })

    },
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}