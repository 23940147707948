<template>
  <div v-cloak v-if="appReady" class="wrapper"
       :style="{'backgroundColor': mainBgColor}">
    <header-app v-once></header-app>
    <router-view></router-view>
    <footer-app v-once></footer-app>
    <cookies-app></cookies-app>
    <modal-app
        modal-id="formModal"
        modal-size="modal-md">
      <template v-slot:body>
        <p class="fs-4 text-center" v-html="GET_FORM_MODAL_TEXT"></p>
      </template>
    </modal-app>
  </div>

  <div id="captcha-container"></div>

  <transition name="fade">
    <loader-app v-if="GET_PRELOADER_STATE"></loader-app>
  </transition>
</template>

<script>
import {defineAsyncComponent} from "vue";
import {mapGetters, mapMutations, mapActions} from "vuex";
import HeaderApp from "@/components/HeaderApp.vue";
import FooterApp from "@/components/FooterApp.vue";
import LoaderApp from "@/components/UI/LoaderApp.vue";

export default {
  components: {
    HeaderApp, LoaderApp, FooterApp,
    CookiesApp: defineAsyncComponent(() =>
        import('@/components/CookiesApp.vue')),
    ModalApp: defineAsyncComponent(() =>
        import('@/components/UI/Modal/ModalApp.vue')),
  },
  data() {
    return {
      ready: false,
      settingsReady: false,
      menuReady: false,
      smartCaptchaReady: false,
    }
  },
  computed: {
    ...mapGetters('MainModule', ['GET_PRELOADER_STATE']),
    ...mapGetters('MenuModule', ['GET_MENU_HEADER', 'GET_MENU_FOOTER']),
    ...mapGetters('PagesModule', ['GET_PAGE']),
    ...mapGetters('SettingsModule', ['GET_RECAPTCHA_SITE_KEY']),
    ...mapGetters('FormModule', ['GET_FORM_MODAL_TEXT']),
    mainDataReady() {
      return this.settingsReady && this.menuReady
    },
    readyToSetCaptcha() {
      return this.smartCaptchaReady && this.settingsReady
    },
    appReady() {
      return !!(this.ready && !this.GET_PRELOADER_STATE && this.mainDataReady && this.GET_PAGE)
    },
    mainBgColor() {
      return this.$route.name === 'contacts' || this.$route.name === 'static_page' ? '#FDFFFE' : '#343635'
    },
  },
  methods: {
    ...mapMutations('MainModule', ['SET_CAPTCHA_VALUE']),
    ...mapActions('SettingsModule', ['FETCH_SETTINGS']),
    ...mapActions('MenuModule', ['FETCH_MENU']),
    ...mapActions('WorksModule', ['FETCH_CURRENT_WORK']),
  },
  watch: {
    // '$route.path'() {
    //     if (!this.$router.hasRoute(this.$route.name)) {
    //         this.$router.push({name: 'NotFound'})
    //     }
    // },
    readyToSetCaptcha(val) {
      if (val) {
        window.smartCaptcha.render('captcha-container', {
          sitekey: this.GET_RECAPTCHA_SITE_KEY,
          invisible: true,
          callback: captchaCallback,
          // test: true, //всегда будет давать задание
          hl: 'ru',
          hideShield: true, //скрыть блок с ссылкой на политику обработки данных
        });
      }
    },
  },
  created() {
    // Яндекс капча
    window.onloadFunction = () => {
      if (!window.smartCaptcha) {
        return;
      }
      this.smartCaptchaReady = true
    }

    const smartCaptchaScript = document.createElement('script')
    smartCaptchaScript.setAttribute('async', '')
    smartCaptchaScript.setAttribute('defer', '')
    smartCaptchaScript.setAttribute('src', 'https://smartcaptcha.yandexcloud.net/captcha.js?render=onload&onload=onloadFunction')
    document.head.append(smartCaptchaScript)
  },
  mounted() {
    this.FETCH_SETTINGS()
        .finally(() => {
          this.settingsReady = true
        })
    this.FETCH_MENU()
        .finally(() => {
          this.menuReady = true
        })

    document.addEventListener('readystatechange', () => {
      this.ready = true
    })

    window.captchaCallback = (token) => {
      this.SET_CAPTCHA_VALUE(token)
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/styles/main.scss';

[v-cloak] {
  visibility: hidden !important;
}

#app {
}

.grecaptcha-badge {
  visibility: hidden;
}

.fade-brief-enter-active, .fade-brief-leave-active {
  transition: opacity 1s ease;
}

.fade-brief-enter, .fade-brief-leave-to {
  opacity: 0;
}

//route-transitions//
.fade-enter-active, .fade-leave-active {
  transition: opacity 1s ease;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}

.route-enter-from {
  opacity: 0;
  transform: translateY(100px);
}

.route-enter-active {
  transition: 1s ease-out all;
}

.route-leave-to {
  opacity: 0;
  transform: translateY(-100px);
}

.route-leave-active {
  transition: 1s ease-in all;
}

//route-transitions//

.item-circle {
  border-radius: 50%;
  border: 1px solid $white;
  background-color: $gray-900;
  color: $white;
}

.spinner {
  display: inline-block;
  height: 14px;
  width: 14px;
  border-left: 3px solid #000;
  border-bottom: 3px solid #000;
  border-right: 3px solid #000;
  border-top: 3px solid transparent;
  border-radius: 50%;
  animation: spinner 1s linear infinite;
}

@keyframes spinner {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.wrapper {
  background-color: $body-dark;
}

.right-block {
  width: 51%;
  @include media-breakpoint-down(md) {
    width: 100%;
  }
}

.container-fluid {
  padding: 0 40px;
  max-width: 1720px;
  @include media-breakpoint-down(md) {
    padding: 0 20px;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.fs-6 {
  font-size: $font-size-xs !important;
}

.fs-5 {
  font-size: $font-size-sm !important;
}

.fs-4 {
  font-size: $font-size-lg !important;
}

.fs-3 {
  font-size: $font-size-xl !important; //24
}

.text-decoration-underline {
  text-underline-offset: 4px;
}

.form-label {
  color: $secondary;
  margin-bottom: .375rem;
}

.form-control {
  border-color: $gray-200;
  border-radius: 5px;
}

textarea {
  resize: none;
}

.link-btn {
  border: none;
  outline: none;
  background-color: transparent;
  color: $white;
}

.btn {
  padding: 55px 60px;
  font-size: $font-size-base * 2.571; //36
  font-weight: $font-weight-semibold;
  @include media-breakpoint-down(xxl) {
    padding: 45px 48px;
    font-size: $font-size-base * 1.71; //24;
  }
  @include media-breakpoint-down(xl) {
    padding: 35px 43px;
    font-size: $font-size-lg; //20
  }
  @include media-breakpoint-down(md) {
    padding: 30px 40px;
    font-size: $font-size-xs; //16
  }
}

.btn-outline-white {
  border-color: $white;
  color: $white;

  &:hover, &:focus, &:focus-visible, &:active, &.active {
    border-color: $success;
    background-color: $success;
    color: $white;
  }

  @media (pointer: coarse) {
    &:hover {
      border-color: $white;
      color: $white;
      background-color: transparent;
    }
  }
}

.btn:disabled, .btn.disabled {
  color: $white;
  background-color: #95C1B2;
  border-color: #95C1B2;
  opacity: 1;
}

.btn:active, .btn:first-child:active, .btn.active {
  color: $white !important;
}

body {
  scrollbar-width: 12px;
  overflow-x: hidden !important;

  &::-webkit-scrollbar {
    width: 10px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $secondary;
  }
}

.overlay {
  background-color: rgba(0, 0, 0, 0.19);
}

.item-index {
  width: 55px;
  height: 55px;
  font-size: $font-size-2xl;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  @include media-breakpoint-down(xxl) {
    width: 48px;
    height: 48px;
    font-size: $font-size-base * 2; //28
  }
  @include media-breakpoint-down(xl) {
    width: 40px;
    height: 40px;
    font-size: $font-size-xl;
  }
  @include media-breakpoint-down(md) {
    width: 30px;
    height: 30px;
    font-size: $font-size-xs;
  }
  @media (max-width: 400px) {
    font-size: $font-size-base;
  }
}

h1, .h1 {
  font-size: $h1-font-size;
  text-transform: uppercase;
  transition: .2s all;
  @media(max-width: 1730px) {
    font-size: $font-size-base * 12.214; //171
  }
  @media(max-width: 1640px) {
    font-size: $font-size-base * 11.5; //161
  }
  @media(max-width: 1540px) {
    font-size: $font-size-base * 10.5; //148
  }
  @media(max-width: 1440px) {
    font-size: $font-size-base * 10.285; //144
  }
  @include media-breakpoint-down(xxl) {
    font-size: $font-size-base * 9.857; //138
  }
  @media(max-width: 1339px) {
    font-size: $font-size-base * 9; //126
  }
  @media(max-width: 1240px) {
    font-size: $font-size-base * 8.714; //122
  }
  @include media-breakpoint-down(xl) {
    font-size: $font-size-base * 8.142; //114
  }
  @media(max-width: 1140px) {
    font-size: $font-size-base * 7.571; //106
  }
  @media(max-width: 1060px) {
    font-size: $font-size-base * 7.357; //103
  }
  @media(max-width: 1019px) {
    font-size: $font-size-base * 7; //98
  }
  @include media-breakpoint-down(lg) {
    font-size: $font-size-base * 6.142; //86
  }
  @media(max-width: 870px) {
    font-size: $font-size-base * 5.285; //74
  }
  @include media-breakpoint-down(md) {
    font-size: $font-size-base * 4.857; //68
  }
  @media(max-width: 670px) {
    font-size: $font-size-base * 4; //56
  }
  @include media-breakpoint-down(sm) {
    font-size: $font-size-3xl; //48
  }
}

h2, .h2 {
  font-size: $h2-font-size;
  @include media-breakpoint-down(xxl) {
    font-size: $font-size-base * 8.428; //118
  }
  @include media-breakpoint-down(xl) {
    font-size: $h3-font-size; //96
  }
  @include media-breakpoint-down(md) {
    font-size: $font-size-base * 3.985; //96
  }

}

h3, .h3 {
  text-transform: uppercase;
  font-size: $h3-font-size;
  @include media-breakpoint-down(xxl) {
    font-size: $font-size-base * 4.57; //64
  }
  @include media-breakpoint-down(xl) {
    font-size: $font-size-base * 4; //56
  }
  @include media-breakpoint-down(md) {
    font-size: $font-size-base * 2.142; //30
  }
}

h4, .h4 {
  text-transform: uppercase;
  font-size: $h4-font-size;
  @include media-breakpoint-down(xxl) {
    font-size: $font-size-3xl; //48
  }
  @include media-breakpoint-down(xl) {
    font-size: $font-size-base * 2.85; //40
  }
  @include media-breakpoint-down(md) {
    font-size: $font-size-xl;
  }
}

a.active.focus, a.active:focus, a.focus, a:active.focus, a:active:focus, a:focus,
button.active.focus, button.active:focus, button.focus, button:active.focus, button:active:focus, button:focus,
.btn.active.focus, .btn.active:focus, .btn.focus, .btn:active.focus, .btn:active:focus, .btn:focus,
input.active.focus, input.active:focus, input.focus, input:active.focus, input:active:focus, input:focus,
textarea.active.focus, textarea.active:focus, textarea.focus, textarea:active.focus, textarea:active:focus, textarea:focus,
select.active.focus, select.active:focus, select.focus, select:active.focus, select:active:focus, select:focus {
  outline: 0 !important;
  outline-color: transparent !important;
  outline-width: 0 !important;
  outline-style: none !important;
  box-shadow: 0 0 0 0 rgba(0, 123, 255, 0) !important;
}

a {
  color: $body-color;
  text-decoration: none;

  &:hover {
    color: $body-color;
  }

  &.hover-link {
    position: relative;

    &:after {
      content: '';
      position: absolute;
      //bottom: -4px;
      bottom: -5px; //test
      left: 0;
      display: inline-block;
      width: 0;
      height: 2px;
      background-color: #000;
      transition: width .3s;
    }

    &:hover {
      color: $body-color;

      &:after {
        width: 100%;
        @media (pointer: coarse) {
          width: 0;
        }
      }
    }
  }

  @media (pointer: coarse) {
    &:hover {
      text-decoration: none;
    }
  }

}

address {
  margin-bottom: 0;
}

.btn-outline-primary {
  color: $body-color;

  &:hover, &:focus, &:focus-visible, &:active, &.active {
    background-color: $success;
    border-color: $success;
    color: $white;
  }

  @media (pointer: coarse) {
    &:hover {
      color: $body-color;
      background-color: transparent;
      border-color: $primary;
    }
  }
}

.divider {
  display: block;
  width: 100%;
  background-color: $secondary;
  height: 1px;
}

.is-required {
  &:after {
    content: ' *';
    color: $danger;
  }
}

.title-strip {
  background-color: $bright-success;
  width: 10px;
  height: auto;
  margin-right: 50px;
  @include media-breakpoint-down(md) {
    width: 6px;
  }
}

.section {
  background-color: $body-dark;
  padding: 150px 0;
  @include media-breakpoint-down(xxl) {
    padding: 130px 0;
  }
  @include media-breakpoint-down(xl) {
    padding: 80px 0;
  }
  @include media-breakpoint-down(lg) {
    padding: 70px 0;
  }
}

.rounded-section {
  background: $gray-100;
  border-radius: 70px;
  @include media-breakpoint-down(xl) {
    border-radius: $border-radius-2xl;
  }
  @include media-breakpoint-down(md) {
    border-radius: $border-radius-xl;
  }
}

.page-top {
  background-color: $green-white;
  padding-top: 280px;
  border-radius: $sectionBR-xxl;
  @include media-breakpoint-down(xxl) {
    padding-top: 240px;
  }
  @include media-breakpoint-down(xl) {
    border-radius: $sectionBR-xl;
    padding-top: 140px;
  }
  @include media-breakpoint-down(md) {
    border-radius: $sectionBR-lg;
  }
}

.services-items {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}
</style>
