<template>
  <div class="loader fw-bold fs-2 d-flex justify-content-center align-items-center">
    <div class="loader__wrapper d-flex">
      <div class="loader__img bg-white rounded-circle d-flex justify-content-center align-items-center">
        <img src="@/assets/logo.svg" alt="ВЕБ-Киборг"/>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LoaderApp",
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/_bootstrap_includes.scss';

.loader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $body-dark;
  z-index: 1030;
  -webkit-animation: fadeInFromNone 0.5s ease-out;
  animation: fadeInFromNone 0.5s ease-out;

  &__img {
    height: 50px;
    width: 50px;

    > img {
      width: 35px;
      height: 35px
    }
  }
}

@-webkit-keyframes titleHeight {
  0% {
    height: 0;
  }

  100% {
    height: 50px;
  }
}

@keyframes titleHeight {
  0% {
    height: 0;
  }

  100% {
    height: 50px;
  }
}

@-webkit-keyframes fadeInFromNone {
  0% {
    display: none;
    opacity: 0;
  }

  1% {
    display: block;
    opacity: 0;
  }

  100% {
    display: block;
    opacity: 1;
  }
}

@keyframes fadeInFromNone {
  0% {
    display: none;
    opacity: 0;
  }

  1% {
    display: block;
    opacity: 0;
  }

  100% {
    display: block;
    opacity: 1;
  }
}
</style>