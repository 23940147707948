import {createRouter, createWebHistory} from 'vue-router'

const routes = [
    {
        path: '/',
        name: 'index',
        component: () => import('../views/HomeView.vue')
    },
    {
        path: '/contacts',
        name: 'contacts',
        component: () => import('../views/ContactsView')
    },
    {
        path: '/services',
        name: 'services',
        component: () => import('../views/ServicesView')
    },
    {
        path: '/services/:slug',
        name: 'service_item',
        component: () => import('../views/SomeServiceView')
    },
    {
        path: '/works',
        name: 'works',
        component: () => import('../views/WorksView'),
    },
    {
        path: '/works/:slug',
        name: 'work_item',
        component: () => import('../views/SomeWorkView'),
    },
    {
        path: '/about',
        name: 'about',
        component: () => import('../views/AboutView')
    },
    {
        path: '/:slug',
        name: 'static_page',
        component: () => import('../views/SomeView')
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'notFound',
        component: () => import('../views/NotFoundView')
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior(to, from, savedPosition) {
        // always scroll to top
        if (to.hash) {
            return {
                el: to.hash,
                behavior: 'smooth',
            }
        } else if (savedPosition) {
            return savedPosition
        } else {
            return { top: 0, behavior: "smooth" }
        }

    },
})

export default router
