import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueCookies from 'vue-cookies'
import VueYandexMetrika from 'vue3-yandex-metrika'

import 'aos/dist/aos.css'
import 'bootstrap/js/dist/modal'
import 'bootstrap/js/dist/collapse'

const app = createApp(App)

app
    .use(store)
    .use(router)
    .use(VueCookies, {})
    .use(VueYandexMetrika, {
        id: process.env.VUE_APP_METRIKA_ID,
        router: router,
        // debug: true,
        env: process.env.NODE_ENV,
        defer: true,
        options: {
            webvisor: true,
            trackHash: true,
            clickmap: true,
            trackLinks: true,
            accurateTrackBounce: true,
        },
    })
    .mount('#app')
