import axios from "axios";

const state = {
    SETTINGS: null,
    EMAIL: '',
    PHONE: '',
}

const getters = {
    GET_EMAIL(state) {
        return state.EMAIL
    },
    GET_EMAIL_LINK(state) {
        return `mailto:${state.EMAIL}`
    },
    GET_PHONE(state) {
        return state.PHONE
    },
    GET_FORMATTED_PHONE(state) {
        let pattern = /(\+7|8)[\s(]?(\d{4})[\s)]?(\d{2})[\s-]?(\d{2})[\s-]?(\d{2})/g;
        return state.PHONE.replace(pattern, '+7 ($2) $3-$4-$5')
    },
    GET_PHONE_LINK(state) {
        return `tel:+${state.PHONE.replace(/\D/img, '')}`
    },
    GET_SETTINGS(state) {
        return state.SETTINGS
    },
    GET_ADDRESS(state) {
        const address = state.SETTINGS.find(item => item.key === 'address')?.value
        const txt = document.createElement("textarea")
        txt.innerHTML = address
        return txt.value
    },
    GET_COPYRIGHT_YEAR_FROM(state) {
        return state.SETTINGS.find(item => item.key === 'copyright_year_from')?.value
    },
    GET_RECAPTCHA_SITE_KEY(state) {
        return state.SETTINGS.find(item => item.key === 'yandex_captcha_client_key')?.value
    },
    GET_SOCIAL_BEHANCE(state) {
        return state.SETTINGS.find(item => item.key === 'social_behance')?.value
    },
    GET_SOCIAL_FLAMP(state) {
        return state.SETTINGS.find(item => item.key === 'social_flamp')?.value
    },
    GET_SOCIAL_HH(state) {
        return state.SETTINGS.find(item => item.key === 'social_hh')?.value
    },
    GET_SOCIAL_INST(state) {
        return state.SETTINGS.find(item => item.key === 'social_inst')?.value
    },
    GET_SOCIAL_VK(state) {
        return state.SETTINGS.find(item => item.key === 'social_vk')?.value
    },
    GET_SOCIAL_YOUTUBE(state) {
        return state.SETTINGS.find(item => item.key === 'social_youtube')?.value
    },
    GET_SOCIAL_TG(state) {
        if (state.SETTINGS.find(item => item.key === 'social_tg')) {
            return state.SETTINGS.find(item => item.key === 'social_tg').value
        }
    },
    GET_SOCIAL_WHATSAPP(state) {
        if (state.SETTINGS.find(item => item.key === 'social_whatsapp')) {
            return state.SETTINGS.find(item => item.key === 'social_whatsapp').value
        }
    },
    GET_WORK_TIMES(state) {
        const workTimes = state.SETTINGS.find(item => item.key === 'work_times')?.value
        const txt = document.createElement("textarea")
        txt.innerHTML = workTimes
        return txt.value
    },
}

const mutations = {
    SET_SETTINGS(state, val) {
        state.SETTINGS = val
        state.EMAIL = val.find(item => item.key === 'contact_email')?.value
        state.PHONE = val.find(item => item.key === 'contact_phone')?.value

        if (val.find(item => item.key === 'scripts_head').value) {
            document.head.insertAdjacentHTML('beforeend', val.find(item => item.key === 'scripts_head').value)
        }
        if (val.find(item => item.key === 'scripts_body_start').value) {
            document.body.insertAdjacentHTML('afterbegin', val.find(item => item.key === 'scripts_body_start').value)
        }
        if (val.find(item => item.key === 'scripts_body_end').value) {
            document.body.insertAdjacentHTML('beforeend', val.find(item => item.key === 'scripts_body_end').value)
        }
    },
}

const actions = {
    async FETCH_SETTINGS({commit}) {
        // store.commit('MainModule/SET_PRELOADER_STATE', true)
        return await axios.get(`${process.env.VUE_APP_API_URL}settings`)
            .then(({data}) => {
                if (data) {
                    commit('SET_SETTINGS', data)
                }
            })
            .catch((err) => {
                throw new Error(err)
            })
            // .finally(() => {
            //     // store.commit('MainModule/SET_PRELOADER_STATE', false)
            // })
    },
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}