const state = {
    PRELOADER: false,
    FORM_PRELOADER: false,
    MENU_IS_OPEN: false,
    BRIEF_IS_OPEN: false,
    CAPTCHA_VALUE: null,
}

const getters = {
    GET_PRELOADER_STATE(state) {
        return state.PRELOADER
    },
    GET_MENU_STATE(state) {
        return state.MENU_IS_OPEN
    },
    GET_BRIEF_STATE(state) {
        return state.BRIEF_IS_OPEN
    },
    GET_FORM_PRELOADER_STATE(state) {
        return state.FORM_PRELOADER
    },
    GET_CAPTCHA_VALUE(state) {
        return state.CAPTCHA_VALUE
    },
}

const mutations = {
    SET_PRELOADER_STATE(state, val) {
        if (!val) {
            setTimeout(() => {
                state.PRELOADER = val
            }, 700)
        } else {
            state.PRELOADER = val
        }
    },
    SET_MENU_STATE(state, val) {
        state.MENU_IS_OPEN = val
    },
    SET_BRIEF_STATE(state, val) {
        state.BRIEF_IS_OPEN = val
    },
    SET_FORM_PRELOADER_STATE(state, val) {
        state.FORM_PRELOADER = val
    },
    SET_CAPTCHA_VALUE(state, val) {
        state.CAPTCHA_VALUE = val
    },
}

const actions = {}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}